body {
  color: beige;
  background-color: #1c1c1c;
  font-family: Arial, sans-serif;
}

.workout-container {
  background-color: #1c1c1c;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  display: none;
  overflow: hidden;
}

.landscape-warning {
  z-index: 9999;
  color: beige;
  text-align: center;
  background: linear-gradient(135deg, #ff6f61, #1c1c1c);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 25vh 2em 2em;
  font-size: 2rem;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.landscape-warning .warning-text {
  max-width: 75%;
  margin: 0 auto;
  line-height: 1.4;
}

#exerciseList {
  padding: 0;
  padding-left: none;
  flex-direction: column;
  align-items: flex-start;
  gap: .5em;
  width: 100%;
  margin-bottom: 1em;
  display: flex;
}

#exerciseList ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#exerciseList li {
  color: #1c1c1c;
  background-color: #00c2c7;
  border: 1px solid beige;
  border-left: none;
  border-radius: 0 25px 25px 0;
  align-items: center;
  width: 70vw;
  height: 3em;
  margin-bottom: 1em;
  padding: .25em 1em;
  font-weight: bold;
  display: flex;
  position: relative;
}

#exerciseList li:last-child {
  margin-bottom: 0;
}

.link-emoji {
  color: beige;
  z-index: 2;
  background-color: #ff6f61;
  border-left: 1px solid beige;
  border-radius: 0 25px 25px 0;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 100%;
  text-decoration: none;
  transition: background .3s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.link-emoji:hover {
  background-color: #ff4f4f;
}

#workout-format {
  justify-content: center;
  gap: .3em;
  width: 100%;
  margin: .3em 0;
  padding: 1em;
  display: flex;
}

.workout-format {
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  padding: .2em;
  display: flex;
}

.workout-format input[type="radio"] {
  display: none;
}

.workout-format .format-number {
  color: #1c1c1c;
  text-align: center;
  background-color: #00c2c7;
  border: 1px solid beige;
  border-radius: 12px;
  min-width: 50px;
  padding: .3em .8em;
  font-size: .9em;
  font-weight: bold;
  display: inline-block;
}

.workout-format .format-description {
  color: beige;
  margin-top: .2em;
  font-size: .7em;
}

.workout-format input[type="radio"]:checked + .format-number {
  color: beige;
  background-color: #ff6f61;
}

#startButton {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 60%;
  max-height: 15%;
  margin-top: auto;
  display: flex;
}

.button {
  cursor: pointer;
  color: #1c1c1c;
  background-color: beige;
  border: 3px solid #00c2c7;
  border-radius: 50vmin;
  outline: 2px solid #ff6f61;
  justify-content: center;
  align-items: center;
  margin: auto 0 1em;
  font-size: 5vw;
  font-weight: bold;
  transition: background .3s, box-shadow .3s;
  display: flex;
  box-shadow: 0 4px 10px #f5f5dc33;
}

.button span {
  text-align: center;
  line-height: 1.2;
}

.button:hover {
  background-color: beige;
  box-shadow: 0 0 15px #f5f5dccc, 0 4px 10px #0003;
}

.button:active {
  transform: scale(.95);
}

@media (width >= 481px) {
  .workout-container {
    padding-top: 2vmin;
  }

  #exerciseList {
    margin-bottom: 1em;
    font-size: 2.5vmin;
  }

  .workout-format .format-number {
    min-width: 70px;
    padding: .5em 1em;
    font-size: 2.5vmin;
  }

  .workout-format .format-description {
    font-size: 2vmin;
  }

  #startButton {
    width: 30%;
  }

  .button {
    font-size: 3vmin;
  }
}

@media (width >= 769px) {
  #exerciseList {
    font-size: 2.5vmin;
  }

  .workout-format .format-number {
    min-width: 60px;
    padding: .4em .9em;
    font-size: 2.5vmin;
  }

  .workout-format .format-description {
    font-size: 2vmin;
  }

  #startButton {
    width: 30%;
  }

  .button {
    font-size: 3vmin;
  }
}
/*# sourceMappingURL=index.681e2b6e.css.map */
